import React, { useEffect } from "react";
import { Row, Col } from "../Common/Grid";
import {
  Avatar,
  Card,
  CardContent,
  Typography,
  IconButton,
  CardMedia,
  makeStyles,
} from "@material-ui/core";
import jagan from "../../assets/jagan.jpeg";
import abhinav from "../../assets/abhinav.jpeg";
import manish from "../../assets/manish.png";

import "./ContactUs.css";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    margin: theme.spacing(7),
    padding: theme.spacing(4),
  },
  details: {
    display: "flex",
    [theme.breakpoints.down('md')]: {
      flexDirection: "column-reverse",
    }
  },
  content: {
    //flex: "1 0 auto",
  },
  cover: {
    width: 151,
  },
  controls: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  playIcon: {
    height: 38,
    width: 38,
  },
  large: {
    width: theme.spacing(15),
    height: theme.spacing(15),
  },
}));

const ContactUs = (): JSX.Element => {
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  const classes = useStyles();

  return (
    <>
      <div className="top-container">
        <Row justify="center" alignItems="center" className="center">
          <Col xs={11} sm={11} md={11} lg={11}>
            <span className="contact-heading-text">Our team</span>
            {/* <p className="sub-heading">Let's build the future together!</p> */}
          </Col>
        </Row>
      </div>
      <div className="bottom-container">
        <Row justify="center" alignItems="center" className="center">
          <Col xs={11} sm={11} md={9} lg={9}>
            <Card className={classes.root}>
              <div className={classes.details}>
                <CardContent className={classes.content}>
                  <Typography component="h4" variant="h4">
                    Jagan - Founder - CEO
                  </Typography>
                  <Typography variant="body1" color="textSecondary">
                    Jagandeep Singh is a highly experienced leader with over 16
                    years of experience in the industry. He has a proven track
                    record of driving innovation and delivering exceptional
                    results for his clients. Jagandeep has worked with some of
                    the most respected companies in the industry, including
                    Lucideus, Quark Software Inc, Aristocrat Technologies, and
                    many more.He has a deep understanding of software
                    development methodologies, project management, and quality
                    assurance. In addition to his extensive experience as a
                    software engineering leader, Jagandeep is also a highly
                    skilled consultant who provides top-class engineering talent
                    to companies. His ability to identify and attract the best
                    engineering talent has made him a highly sought-after
                    consultant in the industry. Jagandeep's leadership style is
                    characterized by a hands-on approach, a commitment to
                    excellence, and a relentless pursuit of innovation. He is a
                    strategic thinker who excels at identifying opportunities
                    for growth and executing on them.
                  </Typography>
                </CardContent>
                <div className={classes.controls}>
                  <IconButton>
                    <Avatar
                      alt="Jagandeep Singh (CEO)"
                      src={jagan}
                      className={classes.large}
                    />
                  </IconButton>
                </div>
              </div>
              <CardMedia
                className={classes.cover}
                image="/static/images/cards/live-from-space.jpg"
                title="Live from space album cover"
              />
            </Card>
          </Col>
          <Col xs={11} sm={11} md={9} lg={9}>
            <Card className={classes.root}>
              <div className={classes.details}>
                <CardContent className={classes.content}>
                  <Typography component="h4" variant="h4">
                    Manish Gupta - Co-founder - CTO
                  </Typography>
                  <Typography variant="body1" color="textSecondary">
                    Manish Gupta is a highly skilled and accomplished technology
                    leader with extensive experience in the software engineering
                    industry. Throughout his career, Manish has held senior
                    engineering roles at top companies such as Niksun, Aurzuv
                    Technologies, and Lucideus. He has a deep understanding of
                    software development methodologies, product engineering, and
                    cloud-based solutions. Manish is an alumnus of the
                    prestigious BITS Pilani, where he earned a degree in
                    Computer Science. His education and experience have equipped
                    him with the technical expertise necessary to lead software
                    engineering teams and deliver exceptional results for his
                    clients. As the CTO of Tekonika Technologies, Manish is
                    responsible for overseeing the development of innovative
                    software solutions that meet the needs of clients across
                    various industries. He is also resposnible in vetting world
                    class engineering talent and help companies deploy the same
                    as per their needs.
                  </Typography>
                </CardContent>
                <div className={classes.controls}>
                  <IconButton>
                    <Avatar
                      alt="Manish Gupta (CTO)"
                      src={manish}
                      className={classes.large}
                    />
                  </IconButton>
                </div>
              </div>
              <CardMedia
                className={classes.cover}
                image="/static/images/cards/live-from-space.jpg"
                title="Live from space album cover"
              />
            </Card>
          </Col>
          <Col xs={11} sm={11} md={9} lg={9}>
            <Card className={classes.root}>
              <div className={classes.details}>
                <CardContent className={classes.content}>
                  <Typography component="h4" variant="h4">
                    Abhinav Narang - Principal Advisor
                  </Typography>
                  <Typography variant="body1" color="textSecondary">
                    Abhinav Narang is a highly experienced product management
                    leader with over 14 years of experience in building highly
                    scalable teams for startups and MNCs. He is currently
                    serving as the Principal Advisor at Tekonika Technologies,
                    where he helps clients develop and launch successful
                    software products. Abhinav holds a degree in Computer
                    Engineering and a postgraduate degree in Marketing, which
                    provides him with a unique blend of technical and business
                    skills. Throughout his career, he has worked with renowned
                    companies such as Fidelity Investments, Infosys, Amway, and
                    many more, where he has led product management teams and
                    driven product innovation. As a product management leader,
                    Abhinav has a deep understanding of customer needs, market
                    trends, and business strategy. He is an expert in building
                    teams, developing and launching successful software products
                    that meet the needs of customers and drive business growth.
                  </Typography>
                </CardContent>
                <div className={classes.controls}>
                  <IconButton>
                    <Avatar
                      alt="Abhinav Narang (Principal Advisor)"
                      src={abhinav}
                      className={classes.large}
                    />
                  </IconButton>
                </div>
              </div>
              <CardMedia
                className={classes.cover}
                image="/static/images/cards/live-from-space.jpg"
                title="Live from space album cover"
              />
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

/*
            {/* <h3>Contact</h3>
          <p className="contact-text">
            Do you have a solution you’d love us to help bring into reality 
            or you have a problem you’d love us to provide a digital solution 
            for. Don’t hesitate to speak to our representative.
          </p>
          <p className="contact-location">New Delhi, India</p>
          <a 
            target="_blank" 
            rel="noopener noreferrer" 
            href="mailto:thello@tekonika.co" 
            className="contact-links"
          >
            <span className="contact-links-text"><EmailIcon /> hello@tekonika.co</span>
          </a>
          <a 
            target="_blank" 
            rel="noopener noreferrer" 
            href="tel:+91 7400077777" 
            className="contact-links"
          >
            <span className="contact-links-text"><PhoneIcon /> +91 7400077777</span>
          </a> */

export default ContactUs;
